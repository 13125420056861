<template>
  <footer class="footer">
    <div>
      <img src="../../public/homeImg/icon-beian.png" alt="" />
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#fff">蜀ICP备2020027237号-1</a>
    </div>
    <div>达州顺泰物流供应链管理有限公司&nbsp;&nbsp;&nbsp;&nbsp;版权所有</div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.footer {
  padding: 84px 400px;
  background-color: #151a22;
  color: white;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  div {
    width: 100%;
  }
  div:first-child {
    display: flex;
    justify-content: center;
    img {
      margin-right: 8px;
    }
  }
}
</style>