<template>
  <header class="header">
    
    <img class="logobj" src="../../public/homeImg/jioayun.png" alt="">
    
    <div class="title_nav">达州顺泰物流供应链管理有限公司</div>
    <section class="firstNav">
      <ul>
        <li v-for="(v, i) in navList" :class="v.name == activeNav ? 'active' : ''" :key="i" @click="navClick(v.name)">
          {{ v.name }}
        </li>
      </ul>
    </section>
  </header>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
    },
    activeNav: {
      type: String,
    }
  },
  methods: {
    //切换nav
    navClick(name) {
      switch (name) {
        case "关于我们":
          this.$emit("handleClick", name, 0);
          break;
        case "合作伙伴":
          this.$emit("handleClick", name, 700);
          break;
        case "联系我们":
          this.$emit("handleClick",name, 1600);
          break;
        default:
          this.$emit("handleClick", name, 0);
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title_nav{
  position: fixed;
  left: 435px;
  top: 27px;
  font-size: 30px;
  font-weight: 500;
  font-family: "思源黑体";
  /*width: 180px;*/
  width:500px;
  height: 44px;
  z-index: 999;
}
.logobj{
  height: 35px;
  width: 285px;
  position: fixed;
  left: 120px;
  top: 32px;
  z-index: 999;
}
.header {
  height: 100px;
  box-sizing: border-box;
  .firstNav {
    position: fixed;
    width: 100%;
    height: 100px;
    display: flex;
    z-index: 99;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    color: white;
    img{
      width: 100%;
      padding: 0;
      margin: 0;
    }
    ul {
      width: 474px;
      margin-right: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    li {
      font-family: "思源黑体";
      font-size: 18px;
      line-height: 100px;
      color: black;
      cursor: pointer;
    }
    .active {
      font-size: 18px;
      color: #D92E1E;
      border-bottom: 3px solid #D92E1E;
    }
  }
  .info {
    color: white;
    padding-top: 82px;
    display: flex;
    justify-content: flex-end;
    div {
      width: 460px;
      height: 300px;
      background: url("../../public/homeImg/index-banner-s1@2x.png") no-repeat;
      background-size: contain;
      padding-top: 60px;
      padding-right: 50px;
      padding-left: 70px;
      margin-right: 220px;
      margin-top: 60px;
      h1 {
        font-size: 26px;
        font-weight: normal;
        margin-bottom: 38px;
        position: relative;
        &::after {
          display: block;
          content: "";
          width: 60px;
          height: 2px;
          background-color: white;
          position: absolute;
          top: 50px;
        }
      }
      p {
        display: flex;
        flex-wrap: wrap;
        span {
          margin-bottom: 18px;
        }
      }
    }
  }
}
</style>